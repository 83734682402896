import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    )
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
        })
      })
    }
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <ul>              
              <Link to="/" className="navbar-item logo" title="Logo">
                <li>
                  <h1>Faroe Inn Import</h1>         
                </li>
                </Link>
                <Link to="/" className="navbar-item logo" title="Logo">
                <li>
                  <p>v/ Hans Birgir Hansen Í lon 1, Boks 3186</p>
                </li>
              </Link>
              <Link to="/" className="navbar-item logo" title="Logo">
                <li>
                  <p>110-Tórshavn Tlf + 298 220123</p>
                </li>
              </Link>              
            </ul>
            {/* Hamburger menu */}
            <div className="navbar-burger burger" data-target="navMenu">
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item box" to="/">
                Forsíða
              </Link>         
              <Link className="navbar-item box" to="/bilegging">
                Bílegging
              </Link>
              <Link className="navbar-item box" to="/link">
                Link
              </Link>
              <Link className="navbar-item box" to="/um-okkum">
                Um okkum
              </Link>
              <Link className="navbar-item box" to="/om-os">
                Om os
              </Link>
              <Link className="navbar-item box" to="/about">
                About
              </Link>                                                     
            </div>
            <div className="navbar-end">              
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
