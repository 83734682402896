import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
              <div className="columns">
                <div className="column is-12">
                <section>
                  <ul className="menu-list">
                    <li className="has-text-centered">
                      Copyright © 1992 - 2025
                    </li>                    
                  </ul>
                </section>
                </div>                
              </div>
            </div>
        </div>
      </footer>
    )
  }
}

export default Footer
